import {Suspense, useEffect, useRef, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import Text from "../components/Text";
import axios from "axios";
import Lottie from "lottie-react";
import lottieTouch from "../touch.json"
import ok from '../ok.json'
import okLand from '../horPlanes.json'
import Consts from "../common/Consts";
import GameTimer from "../components/GameTimer";
import {useSearchParams} from "react-router-dom";

let screenSaverTimeout;
const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {y: {type: "spring", stiffness: 300, damping: 10}}
  },
  closed: {
    opacity: 0,
    y: 20,
    transition: {duration: 0.2}
  }
};
const popupVariants = {
  show: {
    display: 'flex',
    opacity: 1,
    y: '10%',
    transition: {y: {type: "spring", stiffness: 400, damping: 20}}
  },
  hide: {
    display: 'none',
    opacity: 0,
    y: '20%',
    transition: {duration: 0.2}
  }
};
export default function Game({game_slug = null}) {
  let [searchParams, setSearchParams] = useSearchParams();
  const IP_URL = 'https://geolocation-db.com/json/'
  const board = useRef(null)
  const [currentTentativi, setCurrentTentativi] = useState(null)
  const [win, setWin] = useState(false)
  const [land, setLand] = useState(false)
  const clickAnim = useRef(null)
  const okAnim = useRef(null)
  const [animPos, setAnimPos] = useState({x: 0, y: 0})
  const bgAudio = useRef()
  const successAudio = useRef()
  const errorAudio = useRef()
  const [reveal, setReveal] = useState(false)
  const [selectedItem, setSelectedItem] = useState([])
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [answers, setAnswers] = useState([])
  const dimFactor = 0.9
  const fontDimFactor = 0.013
  const [game, setGame] = useState(null)
  const [canHideLogo, setCanHideLogo] = useState(false)
  const [blockGame, setBlockGame] = useState(false)
  const [canPlay, setCanPlay] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [clicks, setClicks] = useState([])
  const [dim, setDim] = useState('')
  const [audioActive, setAudioActive] = useState(false)
  const [screenSaverVisible, setScreenSaverVisible] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [popupContent, setPopupContent] = useState({title: '', content: ''})

  useEffect(() => {
    document.addEventListener('click', function (e) {
      // console.log(e)
      setAnimPos({
        x: e.x, y: e.y
      })
    })
  }, [])

  useEffect(() => {
    if (answers.length === 16) {
      // console.log('Hai vinto!')
      setWin(true)
      setTimeout(() => {
        // okAnim.current.goTo(0, true)
        setWin(false)
        startScreenSaverTimeout(game)
      }, 3000)
    }
  }, [answers])

  useEffect(() => {
    if (win) {
      // okAnim.current.setSpeed(0.5)
      okAnim.current.goToAndPlay(0, true)
      saveContest(1)
      setTimeout(() => {
        reset()
      }, 3000)
    }
  }, [win])

  useEffect(() => {
    // console.log('playy')
    clickAnim.current.goToAndPlay(0, true)
  }, [animPos])

  useEffect(() => {
    let slug_param = ""
    if (game_slug != null) {
      slug_param = '&slug=' + game_slug
    }
    axios.get(Consts.base_url + '/admin/wp-admin/admin-ajax.php?action=game' + slug_param).then(res => {
      // console.log(res.data)
      setData(res)
    }).catch(e => {
      // console.log(e)
      setData({
        data: JSON.parse(localStorage.getItem('game'))
      })
    })
  }, [])

  function setData(res) {
    setGame(res.data)
    startScreenSaverTimeout(res.data)
    localStorage.setItem('game', JSON.stringify(res.data))
    document.getElementsByTagName('body')[0].style.backgroundImage = "url('" + res.data.background + "')"
    document.getElementsByTagName('body')[0].style.overflow = "hidden"
    document.getElementsByTagName('html')[0].style.overflow = "hidden"
    document.getElementById('root').style.overflow = "hidden"
    setCurrentTentativi(res.data.abilita_tentativi)
    setTimeout(() => {
      setReveal(true)
      // revelation()
      checkBoardDimension()
    }, 500)
  }

  useEffect(() => {

    window.addEventListener('resize', checkBoardDimension)
    checkBoardDimension()
    return () => {
      window.removeEventListener('resize', checkBoardDimension)
    }
  }, [board])

  function revelation() {
    // setTimeout(() => {
    // if(blockGame) return
    hideScreenSaver()
    setCanHideLogo(true)
    setTimeout(() => {
      setCanPlay(true)
    }, 1000)
    // }, 2000)
  }


  useEffect(() => {
    if (hasTentativi()) {
      axios.post(Consts.base_url + '/admin/wp-json/contest/state', {
        game: game_slug,
        email: searchParams.get('email')
      }).then(res => {
        if (res.data && res.data.length > 0) {
          console.log(res.data[0])
          setBlockGame(true)
          if (res.data[0].win != null) {
            setPopupContent({
              title: game.title_attempts ? game.title_attempts : "Hai terminato i tentativi!",
              content: game.content_attempts ? game.content_attempts : "Torna a giocare quando arriverà il prossimo contest!",
              image: '',
            })
            setShowPopup(true)
          }
        }
      }).catch(e => {
      })
    }
  }, [currentTentativi]);

  function hasTentativi() {
    return currentTentativi != null && currentTentativi != '0' && currentTentativi != '' && !isNaN(currentTentativi)
  }

  function getTentativi() {
    return parseInt(currentTentativi)
  }

  /* View in fullscreen */
  function openFullscreen() {
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      setFullScreen(true)
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
      setFullScreen(true)
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
      setFullScreen(true)
    }
  }

  /* Close fullscreen */
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullScreen(false)
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
      setFullScreen(false)
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
      setFullScreen(false)
    }
  }

  function checkBoardDimension() {
    if (!board.current) return
    let ww = window.innerWidth
    let wh = window.innerHeight
    setDim(ww + ' x ' + wh + ' - ' + window.devicePixelRatio)
    let landscape = ww >= wh
    setLand(landscape)
    // console.log(landscape)
    let targetDim = landscape ? wh : ww
    let d = (targetDim * dimFactor)
    // console.log(d, landscape)
    let cardTitleFactor = 4
    let cardTextFactor = 2
    if (landscape) {
      cardTitleFactor = 2.5
      cardTextFactor = 1.4
    } else {

    }
    board.current.style.width = d + 'px'
    board.current.style.height = d + 'px'
    let text = document.querySelectorAll('.text')
    if (text) {
      text.forEach(e => {
        e.style.fontSize = (targetDim * fontDimFactor) + 'px'
      })
    }
    let cardTitle = document.querySelector('.card-title')
    let cardText = document.querySelector('.card-text')
    cardTitle.style.fontSize = (targetDim * fontDimFactor * cardTitleFactor) + 'px'
    cardText.style.fontSize = (targetDim * fontDimFactor * cardTextFactor) + 'px'
  }

  function reset() {
    setReveal(false)
    setCanHideLogo(false)
    setCanPlay(false)
    setCurrentIndex(-1)
    setAnswers([])
    setSelectedItem([])
    startScreenSaverTimeout(game)
    setTimeout(() => {
      setReveal(true)
      // revelation()
    }, 1000)
  }

  useEffect(() => {

  }, [currentIndex])

  function getSelected(i, k) {
    return selectedItem.length > 0 && selectedItem[0] === i && selectedItem[1] === k
  }

  function getIndex(i, k) {
    return (k + 1) + (4 * i)
  }

  function inAnswers(i, k) {
    return answers.find(e => e[0] === i && e[1] === k) != null
  }

  useEffect(() => {
    if (!bgAudio.current) return
    // console.log(audioActive)
    if (audioActive) {
      if (!bgAudio.current.isPlaying)
        bgAudio.current.play()
    }
    bgAudio.current.muted = !audioActive
    successAudio.current.muted = !audioActive
    errorAudio.current.muted = !audioActive
  }, [audioActive])

  function playAudio() {
    setAudioActive(!audioActive)
  }

  function hideScreenSaver() {
    console.log('closing screensaver timeout')
    setScreenSaverVisible(false)
    clearTimeout(screenSaverTimeout)
  }

  function startScreenSaverTimeout(_game) {
    if (!_game.screensaver || !_game.screensaver.ss_attivo) {
      hideScreenSaver()
      return
    }
    clearTimeout(screenSaverTimeout)
    console.log('registering timeout')
    screenSaverTimeout = setTimeout(() => {
      console.log('in timeout', canPlay)
      // if(canPlay) {
      //   hideScreenSaver()
      //   return
      // }
      setScreenSaverVisible(true)
    }, parseInt(_game.screensaver.si_attiva_dopo) * 1000)
  }

  function resetScreenSaver() {
    hideScreenSaver()
    startScreenSaverTimeout(game)
  }

  function saveContest(win, callback = null) {
    if (!hasTentativi() || searchParams.get('email') == null) return
    axios.post(Consts.base_url + '/admin/wp-json/contest/save', {
      game: game_slug,
      win: win,
      email: searchParams.get('email')
    }).then(res => {
      if(win == 1) {
        setBlockGame(true)
        setPopupContent({
          title: game.title_win ? game.title_win : "Hai Vinto!",
          content: game.content_win ? game.content_win : "Torna a giocare quando arriverà il prossimo contest!",
          image: '',
        })
        setShowPopup(true)
      }
      if (callback) callback()
    }).catch(e => {
    })
  }

  return (
    <Suspense fallback={"Loading game..."}>
      {
        game && game.screensaver && game.screensaver.ss_attivo && screenSaverVisible &&
        <div onClick={() => {
          setScreenSaverVisible(false)
          startScreenSaverTimeout(game)
        }} className={'video-bg'} style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999}}>
          {
            game.screensaver.ss_media.endsWith('.mp4') &&
            <video className={'orizzontale'} style={{width: '100%', height: '100%', objectFit: 'cover'}}
                   src={game.screensaver.ss_media}
                   preload={'none'} playsInline={true} muted={true} autoPlay={true} loop={true}></video>
          }
          {
            game.screensaver.ss_media_verticale.endsWith('.mp4') &&
            <video className={'verticale'} style={{width: '100%', height: '100%', objectFit: 'cover'}}
                   src={game.screensaver.ss_media_verticale}
                   preload={'none'} playsInline={true} muted={true} autoPlay={true} loop={true}></video>
          }
          <div className="" style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            textAlign: 'center'
          }}>
            <img src="/logo.svg" style={{width: 500, maxWidth: '80%', display: 'block', margin: '0 auto 30px auto'}}
                 alt=""/>
            <button style={{
              backgroundColor: game.colore_tasto_gioca ? game.colore_tasto_gioca : '',
              width: 'auto',
              right: 'auto',
              top: 'auto',
              left: 'auto',
              bottom: 'auto',
              position: 'relative',
              padding: '10px 50px',
              transform: 'translate(0)'
            }} className="gioca">
              <b>GIOCA ORA</b>
            </button>
          </div>
        </div>
      }
      <audio ref={bgAudio} loop style={{
        position: 'absolute',
        opacity: 0,
        visibility: 'hidden'
      }}>
        <source src={'/mallet.mp3'}/>
      </audio>
      <audio ref={successAudio} style={{
        position: 'absolute',
        opacity: 0,
        visibility: 'hidden'
      }}>
        <source src={'/success.mp3'}/>
      </audio>
      <audio ref={errorAudio} style={{
        position: 'absolute',
        opacity: 0,
        visibility: 'hidden'
      }}>
        <source src={'/error.mp3'}/>
      </audio>
      {/*<div style={{position: 'fixed', top: 10, left: 10, color: '#fff', fontWeight: 'bold'}}*/}
      {/*     className="debug-dim">{dim}</div>*/}
      {
        game && <div className="App">
          {
            game.game_timer && game.game_timer.game_timer_attivo && !isNaN(game.game_timer.game_tempo) &&
            <GameTimer className={'timer'} isGameStarted={canPlay && !win} style={{color: '#fff', fontWeight: 'bold'}}
                       onEnd={() => {
                         alert("Il tempo è scaduto!")
                         reset()
                         errorAudio.current.currentTime = 0;
                         errorAudio.current.play()
                       }} maxTime={parseInt(game.game_timer.game_tempo)}/>
          }
          {
            hasTentativi() &&
            <div className={'timer tentativi'}>Hai {getTentativi()} tentativi</div>
          }
          <div className="">
            <img onClick={() => {
              window.parent.postMessage("to_form", "*");
            }} className={
              'logo'
            } style={{
              height: '13vh',
              marginTop: '30px',
              objectFit: 'contain'
            }} src={game.game_logo ? game.game_logo : '/logo.svg'}/>
          </div>
          {
            <motion.div ref={board} className="board"
                        initial={"closed"}
                        variants={{
                          not_play: {
                            translateY: '-63%',
                            translateX: '-50%',
                            transition: {
                              duration: 0.5
                            },
                          },
                          play: {
                            transition: {
                              duration: 0.5
                            },
                            translateY: '-50%',
                            translateX: '-50%'
                          },
                        }}
                        animate={[reveal ? "open" : "closed", canPlay ? 'play' : 'not_play']}>
              <motion.div variants={{
                open: {
                  transition: {
                    staggerChildren: 0.08
                  }
                },
                closed: {
                  transition: {
                    duration: 0.3
                  }
                }
              }} className="board-inner">
                {
                  game && game.game.map((e, i) => {
                    return e.game_row.map((f, k) => {
                      return <motion.div key={'board-item-' + i + k} style={{
                        paddingRight: '0.5%',
                        paddingLeft: '0.5%',
                        paddingBottom: '0.5%',
                        paddingTop: '0.5%'
                      }} className="board-item-outer" animate={{
                        rotateZ: getSelected(i, k) ? [0, -2, 0, 2, 0] : 0
                      }} transition={{
                        ease: 'linear',
                        duration: 0.3,
                        bounce: 0.1,
                        repeat: getSelected(i, k) ? Infinity : null
                      }} variants={itemVariants}>
                        <motion.div whileTap={{
                          scale: !inAnswers(i, k) && canHideLogo ? 0.8 : 1
                        }} animate={getSelected(i, k) ? {
                          scale: 1,
                        } : inAnswers(i, k) || !canHideLogo ? {
                          scale: 1,
                          rotateY: -180
                        } : {
                          scale: 1
                        }}
                                    transition={{
                                      duration: !canPlay ? 2 : inAnswers(i, k) ? 2 : 0.8,
                                      delay: !canPlay && canHideLogo ? getIndex(i, k) * 0.1 : 0,
                                      // ease: [0.66, 0.75, 0.55, 1.49]
                                      type: "spring",
                                      bounce: !canPlay || getSelected(i, k) ? 0.3 : 0.6,
                                    }}
                                    onClick={() => {
                                      if (inAnswers(i, k) || !canHideLogo) return;
                                      let selected = getSelected(i, k)
                                      if (!selected && selectedItem.length > 0) {
                                        // scelto per secondo
                                        let r = Number(game.game[i].game_row[k].related_to)
                                        // scelto per primo
                                        let rt = Number(game.game[selectedItem[0]].game_row[selectedItem[1]].related_to)
                                        if (r === getIndex(selectedItem[0], selectedItem[1]) && rt === getIndex(i, k)) {
                                          // console.log('Giusto')
                                          // successAudio.current.stop()
                                          // successAudio.current.pause().then(res => {
                                          successAudio.current.currentTime = 0;
                                          successAudio.current.play()
                                          // });
                                          setAnswers([
                                            ...answers,
                                            [i, k],
                                            selectedItem
                                          ])
                                          let selected1 = game.game[selectedItem[0]].game_row[selectedItem[1]]
                                          let selected2 = game.game[i].game_row[k]
                                          console.log(selected1, selected2)
                                          if (selected1.luogo_descrizione || selected2.luogo_descrizione) {
                                            setPopupContent({
                                              title: selected1.title || selected2.title,
                                              content: selected1.luogo_descrizione || selected2.luogo_descrizione,
                                              image: selected1.image || selected2.image,
                                            })
                                            setShowPopup(true)
                                          } else {
                                            setShowPopup(false)
                                          }
                                        } else {
                                          // console.log('Sbagliato')
                                          // errorAudio.current.pause().then(res => {
                                          errorAudio.current.currentTime = 0;
                                          errorAudio.current.play()
                                          if (hasTentativi()) {
                                            if (getTentativi() == 1) {
                                              saveContest(0, () => {
                                                alert(game.title_attempts ? game.title_attempts : "Hai termitato i tentativi a disposizione!")
                                                window.location.reload()
                                              })
                                            }
                                            setCurrentTentativi(currentTentativi - 1)
                                          }
                                          // });
                                        }
                                        setSelectedItem([])
                                        setCurrentIndex(-1)
                                        return
                                      }
                                      if (!selected) {
                                        setSelectedItem([i, k])
                                        setCurrentIndex(getIndex(i, k))
                                      } else {
                                        setSelectedItem([])
                                        setCurrentIndex(-1)
                                      }
                                    }} className={'board-item-inner'}
                                    style={{position: 'relative', height: '100%'}}>
                          <motion.div className="board-item retro" style={{
                            backgroundImage: "url('/logo/logo-" + (getIndex(i, k) - 1) + ".png')"
                          }}>

                          </motion.div>
                          {/*<motion.div className={'board-item ' + (getSelected(i, k) ? f.color : '')}>*/}
                          <motion.div className={'board-item'}>
                            <Text image={f.image} hexColor={f.codice_colore} text={f.title}
                                  classes={[f.color, f.type]}/>
                            {/*{*/}
                            {/*    getSelected(i, k) && !inAnswers(i, k) &&*/}
                            {/*    <Lottie animationData={lottieSelected}/>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    inAnswers(i, k) && <Lottie loop={false} animationData={lottieTest}/>*/}
                            {/*}*/}
                          </motion.div>
                        </motion.div>
                      </motion.div>
                    })
                  })
                }
              </motion.div>
            </motion.div>
          }
          <AnimatePresence>
            {!canPlay && <motion.button style={{
              backgroundColor: game.colore_tasto_gioca ? game.colore_tasto_gioca : ''
            }} initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        exit={{opacity: 0}} onClick={revelation} className="gioca">
              <b>GIOCA</b>
            </motion.button>}
          </AnimatePresence>
          <div className="buttons">
            <button onClick={reset}>
              <img alt={'Reset'} src={'/reset.svg'}/>
            </button>
            <button onClick={playAudio}>
              <img alt={'Audio'} src={audioActive ? '/audio-active.svg' : '/audio.svg'}/>
            </button>
            <button className={fullScreen ? 'active' : ''} onClick={() => {
              if (!fullScreen) {
                openFullscreen()
              } else {
                closeFullscreen()
              }
            }}>
              <img alt={'Fullscreen'} src={fullScreen ? '/full-back.svg' : '/full.svg'}/>
            </button>
          </div>
        </div>
      }
      {/*{clicks.map((e, i) => <Lottie lottieRef={clickAnim} key={'anima_' + i} style={{*/}
      {/*    position: 'fixed',*/}
      {/*    top: e.y,*/}
      {/*    left: e.x,*/}
      {/*    width: 30,*/}
      {/*    height: 30*/}
      {/*}} loop={false} autoplay={true} animationData={lottieTouch}/>)}*/}
      <Lottie lottieRef={clickAnim} style={{
        position: 'fixed',
        top: animPos.y - 50,
        left: animPos.x - 50,
        width: 100,
        height: 100,
        pointerEvents: 'none'
      }} loop={false} autoplay={false} animationData={lottieTouch}/>


      {
        <Lottie lottieRef={okAnim} className={'win'} style={{
          position: 'absolute',
          pointerEvents: 'none'
        }} loop={false} autoplay={false} animationData={land ? okLand : ok}/>
      }
      <motion.div initial={'hide'} variants={popupVariants} className={'popup-bg ' + (blockGame ? 'blocked' : '')}
                  animate={[showPopup ? 'show' : 'hide']}></motion.div>
      <motion.div initial={'hide'} variants={popupVariants} animate={[showPopup ? 'show' : 'hide']}
                  className="card-info">
        <div className="card-inner">
          <img src={popupContent.image} style={{width: '100%', marginBottom: 10}} alt=""/>
          <h3 className={'card-title'}><b>{popupContent.title}</b></h3>
          <div className="card-text" dangerouslySetInnerHTML={{__html: popupContent.content}}>
          </div>
        </div>
        {
          !blockGame && <img onClick={() => {
            setShowPopup(false)
          }} src="/close.png" className={'close'} alt=""/>
        }
      </motion.div>
    </Suspense>
  );
}
