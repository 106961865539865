import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Form from "./pages/Form";
import MainMenu from "./pages/MainMenu";
import Mappa from "./pages/Mappa";
import Brochure from "./pages/Brochure";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    }, {
        path: "/app-game/:game",
        element: <App/>,
    }, {
        path: "/app-form/:game",
        element: <Form/>,
    }, {
        path: "/app-menu/:game",
        element: <MainMenu/>,
    }, {
        path: "/mappa",
        element: <Mappa/>,
    }, {
        path: "/brochure",
        element: <Brochure />
    }, {
        path: "/brochure/:game",
        element: <Brochure />
    }
]);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
