import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Consts from "../common/Consts";

export default function MainMenu() {

  const [gameObj, setGameObj] = useState(null)
  const {game} = useParams();

  useEffect(() => {
    if (!game) return
    let slug_param = '&slug=' + game
    axios.get(Consts.base_url + '/admin/wp-admin/admin-ajax.php?action=game' + slug_param).then(res => {
      setGameObj(res.data)
      console.log(res.data)
      document.getElementsByTagName('body')[0].style.backgroundImage = "url('" + res.data.background + "')"
      document.getElementsByTagName('body')[0].style.overflow = "hidden"
      document.getElementsByTagName('html')[0].style.overflow = "hidden"
      document.getElementById('root').style.overflow = "hidden"
    }).catch(e => {
      // console.log(e)
    })
  }, [game])

  return (
    <>
      {
        gameObj && <div className={''} style={{maxWidth: 700, margin: 'auto'}}>
          <div className="">
            <img className={
              ''
            } style={{
              display: 'block',
              margin: '50px auto 0 auto',
              height: '120px',
              maxWidth: '80%',
              objectFit: 'contain'
            }} src={gameObj.game_logo ? gameObj.game_logo : '/logo.svg'}/>
          </div>
          <div className="" style={{textAlign: 'center', marginTop: 50}}>
            <button type="button" onClick={() => {
              if (window.parent !== window) {
                if (gameObj.menu_gioca.url) {
                  window.parent.location.href = gameObj.menu_gioca.url ? gameObj.menu_gioca.url : '/app-form/' + game
                } else {
                  window.parent.postMessage("to_form", "*");
                }
              } else {
                window.location.href = gameObj.menu_gioca.url ? gameObj.menu_gioca.url : '/app-form/' + game
              }
            }} style={{
              position: 'relative',
              width: '90%',
              padding: 10,
              fontSize: 30,
              left: 'auto',
              right: 'auto',
              bottom: 'auto',
              transform: 'none'
            }} className="gioca">
              <b>{gameObj.menu_gioca.title ? gameObj.menu_gioca.title : 'GIOCA ORA'}</b>
            </button>
            <button type="button" onClick={() => {
              if (window.parent !== window) {
                if (gameObj.menu_mappa.url) {
                  window.parent.location.href = gameObj.menu_mappa.url ? gameObj.menu_mappa.url : '/mappa'
                } else {
                  window.parent.postMessage("to_mappa", "*");
                }
              } else {
                window.location.href = gameObj.menu_mappa.url ? gameObj.menu_mappa.url : '/mappa'
              }
            }} style={{
              marginTop: 30,
              position: 'relative',
              width: '90%',
              padding: 10,
              fontSize: 30,
              left: 'auto',
              right: 'auto',
              bottom: 'auto',
              transform: 'none'
            }} className="gioca">
              <b>{gameObj.menu_mappa.title ? gameObj.menu_mappa.title : 'VISUALIZZA MAPPA'}</b>
            </button>
            <button type="button" onClick={() => {
              if (window.parent !== window) {
                if (gameObj.menu_brochure.url) {
                  window.parent.location.href = gameObj.menu_brochure.url ? gameObj.menu_brochure.url : '/brochure' + (game ? '/' + game : '')
                } else {
                  window.parent.postMessage("to_brochure", "*");
                }
              } else {
                window.location.href = gameObj.menu_brochure.url ? gameObj.menu_brochure.url : '/brochure' + (game ? '/' + game : '')
              }
            }} style={{
              marginTop: 30,
              position: 'relative',
              width: '90%',
              padding: 10,
              fontSize: 30,
              left: 'auto',
              right: 'auto',
              bottom: 'auto',
              transform: 'none'
            }} className="gioca">
              <b>{gameObj.menu_brochure.title ? gameObj.menu_brochure.title : 'RICEVI BROCHURE'}</b>
            </button>
          </div>
        </div>
      }
    </>
  )
}
