import {Suspense, useEffect, useRef, useState} from "react";
import axios from "axios";
import '../styles/form.css'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Consts from "../common/Consts";
import {render} from "@testing-library/react";


export default function Form() {
    const [formData, setFormData] = useState({})
    const [formStruct, setFormStruct] = useState(null)
    const [sending, setSending] = useState(false)
    const [privacyChecked, setPrivacyChecked] = useState(false)
    var currentGameName = useRef()
    const nav = useNavigate()
    const {game} = useParams();

    useEffect(() => {
        console.log(formData)
    }, [formData])

    useEffect(() => {
        // console.log(game);
        axios.get(Consts.base_url + '/admin/wp-admin/admin-ajax.php?action=game&slug=' + game).then(res => {
            console.log(res)
            if (!res.data.form) return
            document.getElementsByTagName('body')[0].style.backgroundImage = "url('" + res.data.form.bg + "')"
            document.getElementsByTagName('body')[0].style.overflow = "auto"
            document.getElementsByTagName('html')[0].style.overflow = "auto"
            document.getElementById('root').style.overflow = "auto"

            if (!document.getElementById('pix-script')) {
                var tag = document.createElement('script');
                tag.async = true;
                tag.id = "pix-script"
                tag.src = 'https://js.createsend1.com/javascript/copypastesubscribeformlogic.js';
                var body = document.getElementsByTagName('body')[0];
                body.appendChild(tag);
            }

            // currentGameName.current = res.data.post_title
            setFormStruct({
                builder: res.data.form.builder,
                action: res.data.form.action,
                data_id: res.data.form['data-id'],
                game_logo: res.data.game_logo,
                colore_tasto_gioca: res.data.colore_tasto_gioca,
                description: res.data.form.description,
                form_logo: res.data.form.form_logo
            })
        }).catch(e => {
            // console.log(e)
        })
    }, [game])

    return <Suspense fallback={null}>
        <div className="" style={{padding: '50px 0'}}>
            {
                formStruct &&
                <form onSubmit={async (e) => {
                    e.preventDefault()
                }} id={'subForm'} method={'post'} data-id={formStruct.data_id} action={formStruct.action}
                      className="container js-cm-form">
                    <div className="form">
                        <div className="">
                            <img style={{
                                height: 60,
                                display: 'block',
                                margin: 'auto',
                                maxWidth: '100%',
                                objectFit: 'contain'
                            }} src={formStruct.form_logo ? formStruct.form_logo : formStruct.game_logo ? formStruct.game_logo : '/VisitMalta.png'} alt=""/>
                        </div>
                        <div className="" style={{
                            padding: 20,
                            marginTop: 30,
                            textAlign: 'center'
                        }} dangerouslySetInnerHTML={{__html: formStruct.description}}>
                        </div>
                        {
                            formStruct.builder.map((e, i) => {
                                return <div style={{
                                    marginTop: i < formStruct.builder.length ? 20 : 0
                                }} key={i}>
                                    <label>{e.form_item.item_label}</label>
                                    <input name={e.form_item.item_name} onChange={(ev) => {
                                        setFormData(prevState => {
                                            prevState[e.form_item.item_label] = ev.target.value
                                            return prevState
                                        })
                                    }} type={e.form_item.item_type} placeholder={e.form_item.item_label}
                                           className={'input ' + e.form_item.item_classes}/>
                                </div>
                            })
                        }
                        <div className="" style={{marginTop: 20}}>
                            <label htmlFor="p">
                                <input onChange={(e) => {
                                    // console.log(e.target.checked)
                                    setPrivacyChecked(e.target.checked)
                                }} type="checkbox" style={{marginRight: 10, marginLeft: 0}} id="p"/>
                                Inserendo il mio indirizzo email dichiaro
                                di accettare quanto scritto nell'<a target="_blank"
                                                                    href="https://www.visitmalta.com/it/privacy-policy-and-cookie-policy/">informativa
                                sulla privacy</a>.
                            </label>
                        </div>
                    </div>
                    <div style={{paddingTop: 30, width: '60%'}} className="invia-outer">
                        <button type="submit" onClick={async (e) => {
                            if (!privacyChecked) {
                                alert("Accetta la nostra privacy policy prima di continuare")
                                e.preventDefault()
                                return
                            }

                            if (sending) return
                            setSending(true)
                            // console.log(formData)
                            try {
                                await axios.post(Consts.base_url + '/admin/wp-json/game/v1/form', formData);
                                // alert("Inizia il gioco!")
                                // nav("/")
                            } catch (e) {
                                alert("C'è stato un errore durante il salvataggio. Riprova.")
                            } finally {
                                setSending(false)
                            }

                        }} style={{position: 'relative', width: '100%', padding: 10, fontSize: 30, backgroundColor: formStruct.colore_tasto_gioca ? formStruct.colore_tasto_gioca : ''}} className="gioca">
                            {
                                !sending && <b>INVIA</b>
                            }
                            {
                                sending && <div className={'loader'}></div>
                            }
                        </button>
                    </div>
                </form>
            }
        </div>
    </Suspense>
}
