import './App.css';
import Game from "./pages/Game";
import {useParams} from "react-router-dom";


function App() {
    const {game} = useParams();
    return <Game game_slug={game}/>
}

export default App;
