export default function Mappa() {


  return <iframe
    title={'Mappa di Malta'}
    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d206698.13081193302!2d14.416101352629001!3d35.95532816276466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sit!2sit!4v1706520287539!5m2!1sit!2sit"
    style={{border: 0, position: 'fixed', inset: 0, width: '100%', height: '100%'}} allowFullScreen="" loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"></iframe>

}
