import {useEffect, useState} from "react";

let interval;
export default function GameTimer({onEnd, maxTime, isGameStarted, ...props}) {

  const [time, setTime] = useState(maxTime)

  const cleanUp = () => {
    setTime(maxTime)
    clearInterval(interval)
  }

  useEffect(() => {
    if(time === 0) {
      onEnd()
      cleanUp()
    }
  }, [time])

  useEffect(() => {
    if(isGameStarted) {
      interval = setInterval(() => {
        setTime(maxTime--)
      }, 1000)
    } else {
      cleanUp()
    }
    return () => {
      cleanUp()
    }
  }, [isGameStarted]);

  return <div {...props}>
    {new Date(time * 1000).toISOString().slice(14, 19)}
  </div>

}
