import {Suspense, useEffect, useRef, useState} from "react";
import axios from "axios";
import Consts from "../common/Consts";
import {useNavigate, useParams} from "react-router-dom";

export default function Brochure() {
  const navigate = useNavigate()
  const [sending, setSending] = useState(false)
  const [privacyChecked, setPrivacyChecked] = useState(false)
  const [phone, setPhone] = useState(false)
  const [firstName, setFirstName] = useState(false)
  const [lastName, setLastName] = useState(false)
  const [gameObj, setGameObj] = useState(null)
  const {game} = useParams();

  useEffect(() => {
    if (!game) return
    let slug_param = '&slug=' + game
    axios.get(Consts.base_url + '/admin/wp-admin/admin-ajax.php?action=game' + slug_param).then(res => {
      document.getElementsByTagName('body')[0].style.backgroundImage = "url('" + res.data.background + "')"
      document.getElementsByTagName('body')[0].style.overflow = "auto"
      document.getElementsByTagName('html')[0].style.overflow = "auto"
      document.getElementById('root').style.overflow = "auto"
      setGameObj(res?.data)
    }).catch(e => {
      // console.log(e)
    })
  }, [game])

  return <Suspense fallback={null}>
    <div className="" style={{padding: '50px 0'}}>
      <form onSubmit={async (e) => {
        e.preventDefault()
      }} id={'brochureForm'} method={'post'} action="" className="container js-cm-form">
        <div className={'form'}>
          <div className="">
            <img style={{
              height: 60,
              display: 'block',
              margin: 'auto',
              maxWidth: '100%',
              objectFit: 'contain'
            }} src={gameObj?.game_logo ? gameObj.game_logo : Consts.base_url + '/admin/wp-content/uploads/2023/10/VMIM_CMYK-e1697696440730.png'} alt=""/>
          </div>
          {/*<div className="" style={{*/}
          {/*  padding: 20,*/}
          {/*  marginTop: 30,*/}
          {/*  textAlign: 'center',*/}
          {/*  fontWeight: 'bold',*/}
          {/*  fontSize: '24px'*/}
          {/*}}>Form Brochure*/}
          {/*</div>*/}
          <div style={{marginTop: '20px', width: '100%'}}>
            <label>Nome</label>
            <input name="first_name" className={'input'}
                   onChange={(ev) => {
                     setFirstName(ev.target.value)
                   }}/>
          </div>
          <div style={{marginTop: '20px', width: '100%'}}>
            <label>Cognome</label>
            <input name="last_name" className={'input'} onChange={(ev) => {
              setLastName(ev.target.value)
            }}/>
          </div>
          <div style={{marginTop: '20px', width: '100%'}}>
            <label>Telefono</label>
            <input name="phone" className={'input'} onChange={(ev) => {
              setPhone(ev.target.value)
            }}/>
          </div>
          <div className="" style={{marginTop: 20}}>
            <label htmlFor="p">
              <input onChange={(e) => {
                // console.log(e.target.checked)
                setPrivacyChecked(e.target.checked)
              }} type="checkbox" style={{marginRight: 10, marginLeft: 0}} id="p"/>
              Inserendo il mio numero di telefono dichiaro
              di accettare quanto scritto nell'<a target="_blank"
                                                  href="https://www.visitmalta.com/it/privacy-policy-and-cookie-policy/">informativa
              sulla privacy</a>.
            </label>
          </div>
        </div>
        <div style={{paddingTop: 30, width: '60%'}} className="invia-outer">
          <div style={{paddingTop: 30}} className="invia-outer">
            <button type="submit" onClick={async (e) => {
              if (!privacyChecked) {
                alert("Accetta la nostra privacy policy prima di continuare")
                e.preventDefault()
                return
              }
              if (!phone || !firstName || !lastName) {
                alert("Compila tutti i campi prima di continuare")
                e.preventDefault()
                return
              }

              if (sending) return
              setSending(true)
              try {
                const res = await axios.post(Consts.base_url + '/admin/wp-json/brochure/form', {
                  phone: phone,
                  first_name: firstName,
                  last_name: lastName
                });
                alert(res.data.message)
                // nav("/")
              } catch (e) {
                alert("C'è stato un errore durante il salvataggio. Riprova.")
              } finally {
                setSending(false)
                // alert("Messaggio inviato con successo!")
                navigate("/menu/" + game)
              }
            }} style={{position: 'relative', width: '100%', padding: 10, fontSize: 30}} className="gioca">
              {
                !sending && <b>INVIA</b>
              }
              {
                sending && <div className={'loader'}></div>
              }
            </button>
          </div>
        </div>
      </form>
    </div>
  </Suspense>
}
